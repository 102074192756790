import React from "react";
import "../Styles/Home.css";
import frothcrm1 from "../Assets/crm1.png";
import img1 from "../Assets/Icon (3).png";
import img2 from "../Assets/Icon (4).png";
import img3 from "../Assets/Icon (5).png";
import home3 from "../Assets/crm3.jpg";
import home4 from "../Assets/crm4.png";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  const handleContact = () => {
    window.open("/ContactUs", "_blank");
    navigate("/");
  };

  return (
    <div className="container-fluid">
      <div className="Home-Content">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h1>
                <span className="spanBlack">
                  Enhance your sales,
                  <br />
                  marketing, and
                </span>
                <span className="spanRed"> service</span>
                <br />{" "}
                <span className="spanRed">
                  operations with Froth <br /> CRM
                </span>
              </h1>
            </div>
            <div className="Sub-Content">
              <p>
                Froth CRM comprehends the complexities of modern business
                challenges in today’s fiercely competitive environment.
              </p>
            </div>
            <button
              id="contact"
              className="btn btn-danger"
              onClick={handleContact}
            >
              Contact Us
            </button>
          </div>
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={frothcrm1}
              alt="FrothCRM Page 1"
            />
          </div>
        </div>
      </div>
      <div className="Home-Content3">
        <div className="row d-flex align-items-center">
        <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={home3}
              alt="FrothCRM Page 1"
            />
          </div>
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h2>
                <span className="spanBlack">Why Froth-</span>
                <span className="spanRed">CRM</span>
              </h2>
            </div>
            <div className="Sub-Content">
              <p>
                We provide custom-tailored solutions to meet your precise
                requirements, empowering you to make informed decisions that
                drive revenue and foster customer loyalty.
                <br />
                <br />
                Join our satisfied community today and explore the unique
                benefits of Froth CRM.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-Content2">
        <div id="Container">
        <div className="d-flex align-items-center justify-content-center">
            <span style={{marginTop:'1rem',width:'9rem',border:'1px solid #ff0000'}}></span>
          </div>
          <h3 className="text-center mb-4">
            One{" "}
            <span className="span-Red">
              CRM platform
            </span>{" "}
           to rule all of them
          </h3>
          <div className="row" style={{ marginTop: "1rem" }}>
            <div className="col-md-4" id="card-Content">
              <img src={img1} alt="Feature 1" className="mb-3 card-imgs" />
              <strong className="mb-3">
              360-Degree Insights<br />
              </strong>
              <p>
              Achieve a comprehensive understanding of your customers through the creation of a centralized repository for all customer data. Gain valuable insights that can elevate and nurture your business relationships.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img2} alt="Feature 2" className="mb-3 card-imgs" />
              <strong className="mb-3">
              Embrace Freedom
              </strong>
              <p>
              Achieve significant cost savings by leveraging the capabilities of Froth CRM. Enjoy the freedom and flexibility to tailor your CRM to precisely match your unique requirements.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img3} alt="Feature 3" className="mb-3 card-imgs" />
              <strong className="mb-3">
              Seamless Integration
              </strong>
              <p>
              Enhance productivity throughout your organization with our REST API, enabling seamless CRM integration with core business systems. This facilitates effortless sharing and collaboration across all boundaries.
              </p>
            </div>
          </div>
          <div className="row" style={{ marginTop: "0rem" }}>
            <div className="col-md-4" id="card-Content">
              <img src={img1} alt="Feature 1" className="mb-3 card-imgs" />
              <strong className="mb-3">
              Thriving Community
              </strong>
              <p>
              Our CRM Source model thrives on a vibrant community that continually contributes improvements, documentation, and rigorous testing, all meticulously overseen by Froth CRM.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img2} alt="Feature 2" className="mb-3 card-imgs" />
              <strong className="mb-3">Boost Sales
              </strong>
              <p>
              Seize Every Opportunity Make the most of your comprehensive customer profiles to convert leads and optimize your sales strategies. Our CRM equips you with the tools needed to capture vital leads, manage opportunities, and generate personalized quotes, freeing up valuable time for selling.
              </p>
            </div>
            <div className="col-md-4" id="card-Content">
              <img src={img3} alt="Feature 3" className="mb-3 card-imgs" />
              <strong className="mb-3">Build Stronger Connections
              </strong>
              <p>
              Streamline Your Data Our adaptable data model empowers you to create a customized repository for all your customer data, leveraging critical insights to enhance efficiency, gain a deeper understanding of your customers' needs
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Home-Content3">
        <div className="row d-flex align-items-center">
          <div className="form-container col-md-6">
            <div className="Main-Content">
              <h3>
                <span className="spanBlack">
                  Revolutionize Your Business with
                </span>{" "}
                <span className="spanRed">Froth CRM</span>
              </h3>
            </div>
            <div className="Sub-Content" style={{ marginTop: "0.4rem" }}>
              <br />
              <strong style={{ color: "#505050" }}>Engage Your Audience</strong>
              <p>
                Deliver the Right Message. Enhance customer engagement with
                personalized experiences and consistent messaging. Automate
                customer segmentation, deliver targeted marketing content, and
                track crucial activities to generate more leads and nurture the
                sales pipeline.
                <br />
              </p>
              <strong style={{ color: "#505050" }}>Success Stories</strong>
              <p>
                Elevate Customer Satisfaction, Satisfy and Delight.Empower your
                customer service team to provide exceptional service by
                eliminating information silos and equipping them with the tools
                needed to serve customers in a smarter, faster, and more
                professional manner.
              </p>
            </div>
          </div>
          <div className="form-container col-md-6">
            <img
              className="img-fluid"
              id="frothcrm-img"
              src={home4}
              alt="FrothCRM Page 1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
