import React, { useState, useEffect } from "react";
import "intl-tel-input/build/css/intlTelInput.css";
import "../Styles/ContactUs.css";
import PhoneInput from "react-phone-input-2";
import contactImg from "../Assets/contactform.png";
import { useNavigate } from "react-router-dom";
import { apiUrl } from "../Pages/Config";
import { toast } from "react-toastify";

const ContactUs = () => {
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState(null);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [designation, setDesignation] = useState("");
  const [message, setMessage] = useState("");

  const handlePhoneChange = (value) => setPhone(value);

  const [formErrors, setFormErrors] = useState({});
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    try {
      const response = await fetch("https://frothcrm.com/crm_login.php", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({}).toString(),
      });
      if (response.ok) {
        const data = await response.json();
        setAccessToken(data.access_token);
      } else {
        alert("Failed to login. Please try again.");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Failed to login. Please try again.");
    }
  };

  const handleClearContact = () => {
    setName("");
    setPhone("");
    setEmail("");
    setCompany("");
    setDesignation("");
    setMessage("");
    setFormErrors({});
  };

  const handleBack = () => navigate("/");

  const validateForm = () => {
    const errors = {};
    if (!name) errors.name = "Name required";
    if (!phone) errors.number = "Phone Number required";
    if (!email) errors.email = "Email required";
    else if (!emailRegex.test(email)) errors.email = "Valid Email required";
    if (!company) errors.company = "Company Name required";
    if (!designation) errors.designation = "Designation required";
    if (!message) errors.message = "Message required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const contactUs = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const plan = localStorage.getItem("plan");
    const payload = {
      contactus_company_name: company,
      contactus_name: name,
      contactus_product: "FrothCRM",
      contactus_email_id: email,
      contactus_phone_number: phone,
      contactus_designation: designation,
      contactus_message: message,
      contactus_plan_type: plan,
    };

    const response = await fetch(apiUrl + "contactusLeadCreation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      // const data = await response.json();
      toast.success(
        "We have received your request,our team will contact you shortly",
        { position: "top-right", autoClose: 3000, className: "custom-toast" }
      );
      handleClearContact();
      localStorage.removeItem("plan");
    } else {
      toast.warn("Something went wrong! Try again", {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="container row" id="contact-page">
      <div className="form-container col-md-6" style={{ padding: "4rem" }}>
        <img src={contactImg} style={{ width: "100%" }} alt="Contact Form" />
      </div>
      <div className="form-container col-md-6 mt-1">
        <h2 className="mb-3" style={{ color: "#FF0000" }}>
          Get in Touch
        </h2>
        <p>Let's build something unique!</p>
        <form onSubmit={contactUs}>
          <div className="row g-3">
            <div className="col-md-6">
              <label htmlFor="conName" className="form-label">
                Name <span className="required">*</span>
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-user"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="conName"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Name"
                />
              </div>
              {formErrors.name && (
                <span style={{ color: "#FF0000" }}>{formErrors.name}</span>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="phone" className="form-label">
                Phone Number <span className="required">*</span>
              </label>
              <div className="input-group">
                <PhoneInput
                  country="us"
                  value={phone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: "100%",
                    height: "38px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ccc",
                  }}
                  buttonStyle={{
                    borderRadius: "5px 0 0 5px",
                    border: "1px solid #ccc",
                    background: "white",
                  }}
                />
              </div>
              {formErrors.number && (
                <span style={{ color: "#FF0000" }}>{formErrors.number}</span>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="conEmail" className="form-label">
                Email <span className="required">*</span>
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-envelope"></i>
                </span>
                <input
                  type="email"
                  className="form-control"
                  id="conEmail"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
              </div>
              {formErrors.email && (
                <span style={{ color: "#FF0000" }}>{formErrors.email}</span>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="conCompany" className="form-label">
                Company <span className="required">*</span>
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-building"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="conCompany"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  placeholder="Company"
                />
              </div>
              {formErrors.company && (
                <span style={{ color: "#FF0000" }}>{formErrors.company}</span>
              )}
            </div>
            <div className="col-md-6">
              <label htmlFor="conDesignation" className="form-label">
                Designation <span className="required">*</span>
              </label>
              <div className="input-group mb-3">
                <span className="input-group-text">
                  <i className="fas fa-gear"></i>
                </span>
                <input
                  type="text"
                  className="form-control"
                  id="conDesignation"
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  placeholder="Designation"
                />
              </div>
              {formErrors.designation && (
                <span style={{ color: "#FF0000" }}>
                  {formErrors.designation}
                </span>
              )}
            </div>
            <div className="col-md-12">
              <label htmlFor="conMsg" className="form-label">
                Message <span className="required">*</span>
              </label>
              <textarea
                className="form-control"
                id="conMsg"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Message"
              />
              {formErrors.message && (
                <span style={{ color: "#FF0000" }}>{formErrors.message}</span>
              )}
            </div>
            <div className="w-100 d-flex justify-content-center gap-4">
              <button
                id="back-button"
                className="btn btn-outline-danger w-20 font-weight-bold"
                type="button"
                onClick={handleBack}
              >
                Back
              </button>
              <button
                id="submit-button"
                className="btn btn-danger w-20 font-weight-bold"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactUs;
