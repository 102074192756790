import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideTicketDetailsPopup, setTickets } from "../../Pages/Store";
import "bootstrap/dist/css/bootstrap.min.css";
import { apiUrl } from "../../Pages/Config";
import { toast } from "react-toastify";
import { RiDeleteBin2Line } from "react-icons/ri";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

const TicketDetails = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);
  const { ticketDetails } = useSelector((state) => state.root);
  const [commentsTable, setCommentsTable] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [tickets, setTicketsData] = useState([]);
  const [commentsNotesTable, setCommentsNotesTable] = useState(
    ticketDetails?.notes
  );

  const handleClose = () => {
    dispatch(hideTicketDetailsPopup());
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
  
    const maxSizeMB = 5; // Max size per file
    const maxTotalSizeMB = 50; // Max total size
    const maxFiles = 10; // Max number of files
  
    let totalSize = 0;
    let validFiles = [...files];
    let errors = {};
  
    // Allowed file extensions
    const allowedExtensions = new Set([
     'png', 'jpg', 'jpeg', 'gif', 'tiff', 'pdf',
    'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx',
    'txt', 'html', 'json', 'csv'])
  
    // Convert MB to bytes
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    const maxTotalSizeBytes = maxTotalSizeMB * 1024 * 1024;
  
    if (validFiles.length + selectedFiles.length > maxFiles) {
      errors.file = `Maximum files to upload: ${maxFiles}`;
    }
  
    // Validate each file
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (!allowedExtensions.has(fileExtension)) {
        errors.file = `Unsupported file format`
        break; // Stop further validation if unsupported file type found
      }
  
      if (file.size > maxSizeBytes) {
        errors.file = `Maximum upload file size: ${maxSizeMB} MB`;
        break; // Stop further validation if file size exceeds the limit
      } else {
        totalSize += file.size;
        validFiles.push(file);
      }
    }
  
    if (totalSize > maxTotalSizeBytes) {
      errors.file = `Total upload file size exceeds ${maxTotalSizeMB} MB`;
      validFiles = []; // Reset valid files
    }
  
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      fileInputRef.current.value = null; // Clear input field
    } else {
      setFiles(validFiles);
      setFormErrors({});
      fileInputRef.current.value = null; // Clear input field
    }
  };

  const handleFileDelete = (fileToDelete) => {
    setFiles(files.filter((file) => file !== fileToDelete));
  };

  const clearInputs = () => {
    setTitle("");
    setDescription("");
    setFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  useEffect(() => {
    if (tickets?.length !== 0) {
      showTicketNotes(ticketDetails?.case_number);
    }
  }, [tickets]);

  const showTicketNotes = (caseNumber) => {
    const searchTicket = tickets.find(
      (ticket) => ticket?.case_number === caseNumber
    );
    setCommentsNotesTable(searchTicket?.notes);
  };

  const handleComments = () => {
    setFormErrors({}); // Clear any previous errors

    setCommentsTable(!commentsTable);
    if (!commentsTable && containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  const UpdateStatus = async (statusApi) => {
    try {
      let payload = {
        ticket_id: ticketDetails?.id,
        status: statusApi,
      };

      const response = await fetch(`${apiUrl}updateTicket`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        if (statusApi === "Closed_Closed") {
          toast.success("Ticket has been Closed", {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          toast.success("Ticket has been Reopened", {
            position: "top-right",
            autoClose: 3000,
          });
        }

        const ticketResponse = await fetch(
          `${apiUrl}fetchUserTickets/${localStorage.getItem("Id")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const fetchResponse = await ticketResponse.json();
        const data =fetchResponse?.length>0 ? fetchResponse?.filter(
          (project) =>
            project.product_name &&
            project.product_name.toLowerCase() === "frothcrm"
        ) : [];
        dispatch(setTickets(data));
        handleClose();
      } else {
        toast.error("Failed", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const documentUrls = ticketDetails?.documents.map(
    (document) => document.download_url
  );

  const splitTextByLength = (text, length) => {
    const regex = new RegExp(`(.{1,${length}})`, "g");
    return text.match(regex);
  };

  const validateForm = () => {
    const errors = {};
    const trimmedTitle = title?.trim();
    if (!trimmedTitle) {
      errors.title = "Subject required";
    } else if (trimmedTitle.length > 200) {
      errors.title = "Subject cannot exceed 200 characters";
    }
    if (!description?.trim()) errors.description = "Comments required";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendComments = async () => {
    if (!validateForm()) return;

    try {
      const formData = new FormData();
      formData.append("ticket_id", ticketDetails?.id);
      formData.append("subject", title);
      formData.append("description", description);
      if (files) {
        files.forEach((file) => {
          formData.append("attachments[]", file);
        });
      }

      const response = await fetch(`${apiUrl}createNote`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        toast.success("Comments added successfully", {
          position: "top-right",
          autoClose: 3000,
        });

        const ticketResponse = await fetch(
          `${apiUrl}fetchUserTickets/${localStorage.getItem("Id")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const fetchResponse = await ticketResponse.json();
        const data = fetchResponse?.length>0 ? fetchResponse?.filter(
          (project) =>
            project.product_name &&
            project.product_name.toLowerCase() === "frothcrm"
        ) : [];
        setTicketsData(data);
        dispatch(setTickets(data));
        clearInputs();
      } else {
        toast.error("Failed to add comment", {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  return (
    <div
      id="addTicketPopup"
      style={{
        display: "block",
        position: "fixed",
        zIndex: 1050, // Make sure this is higher than the header
      }}
    >
      <div
        className="card popup"
        style={{ margin: "10px", padding: "1.5rem", width: "60%", top: "23%" }}
        ref={containerRef}
      >
        <div className="details-popup">
          <div
            className="details-header"
            style={{
              padding: "0.7rem",
              borderBottom: "1px solid #ccc",
              marginBottom: "0.3rem",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h5>Ticket Details</h5>
            <span
              className="closeDetails"
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              title="Close Popup"
            >
              X
            </span>
          </div>
          <div
            className="modal-body"
            style={{ padding: "0.9rem", maxHeight: "23rem", overflow: "auto" }}
          >
            <div className="row">
              <div className="col-md-6">
                <p>
                  <strong style={{ marginRight: "4px" }}>Case Number:</strong>{" "}
                  <span id="popupTicketNumber">
                    {ticketDetails?.case_number || "-"}
                  </span>
                </p>
                <p>
                  <strong style={{ marginRight: "4px" }}>Case Title:</strong>{" "}
                  <span id="popupTicketName">{ticketDetails?.name || "-"}</span>
                </p>
                <p>
                  <strong style={{ marginRight: "4px" }}>Created on:</strong>{" "}
                  <span id="popupCreated">
                    {ticketDetails?.date_entered
                      ? ticketDetails.date_entered.split(" ")[0]
                      : "-"}
                  </span>
                </p>
                <p>
                  <strong style={{ marginRight: "4px" }}>Created By:</strong>{" "}
                  <span id="popupCreatedName">
                    {ticketDetails?.created_by || "-"}
                  </span>
                </p>
              </div>
              <div className="col-md-6">
                <p>
                  <strong style={{ marginRight: "4px" }}>Status:</strong>{" "}
                  <span id="popupStatus">
                    {ticketDetails?.status
                      ? ticketDetails.status.split("_")[1]
                      : "-"}
                  </span>
                </p>
                <p>
                  <strong style={{ marginRight: "4px" }}>Severity:</strong>{" "}
                  <span id="popupSeverity">
                    {ticketDetails?.severity || "-"}
                  </span>
                </p>
                <p>
                  <strong style={{ marginRight: "4px" }}>Assign To:</strong>{" "}
                  <span id="popupSeverity">
                    {ticketDetails?.assigned_user_name || "-"}
                  </span>
                </p>

                <p>
                  <strong style={{ marginRight: "8px" }}>
                    SLA Expires on:
                  </strong>{" "}
                  <span id="slaTime">
                    {ticketDetails?.resolution_target_time || "-"}
                  </span>
                </p>
              </div>
              <div className="col-md-12">
                <p>
                  <strong style={{ marginRight: "4px" }}>Description:</strong>{" "}
                  <span id="popupDescription">
                    {ticketDetails?.description || "-"}
                  </span>
                </p>
                <p>
                  <strong style={{ marginRight: "4px" }}>Resolution:</strong>{" "}
                  <span id="popupResolution">
                    {ticketDetails?.resolution || "-"}
                  </span>
                </p>
                <p>
                  <strong style={{ marginRight: "4px" }}>Attachments:</strong>
                  {documentUrls?.length > 0 ? (
                    <ol style={{ paddingLeft: "20px" }}>
                      {documentUrls.map((url, index) => (
                        <li key={index}>
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: "black" }}
                          >
                            {ticketDetails?.documents[index]?.document_name ||
                              "Document"}
                          </a>
                        </li>
                      ))}
                    </ol>
                  ) : (
                    "-"
                  )}
                </p>
              </div>
            </div>

            {commentsTable && (
              <>
                {/* Comments Table */}
                <div id="commentsTableContainer" style={{ marginTop: "2%" }}>
                  <table className="table table-bordered">
                    <thead style={{ textAlign: "center", textWrap: "nowrap" }}>
                      <tr>
                        <th scope="col" style={{ width: "7%" }}>
                          SL NO
                        </th>
                        <th scope="col" style={{ width: "15%" }}>
                          Date
                        </th>
                        <th scope="col" style={{ whiteSpace: "nowrap" }}>
                          Subject
                        </th>
                        <th scope="col">Comments</th>
                        <th scope="col" style={{ width: "13%" }}>
                          Documents
                        </th>
                      </tr>
                    </thead>
                    <tbody id="commentsTableBody">
                      {commentsNotesTable?.length > 0 ? (
                        <>
                          {commentsNotesTable?.map((comment, index) => (
                            <tr key={comment.id}>
                              <td
                                id="table-td-data"
                                style={{ textAlign: "center" }}
                              >
                                {index + 1}
                              </td>
                              <td
                                id="table-td-data"
                                style={{
                                  textAlign: "center",
                                  textWrap: "nowrap",
                                }}
                              >
                                {comment.date_entered?.split(" ")[0]}
                              </td>
                              <td
                                id="table-td-data"
                                style={{ textAlign: "center" }}
                              >
                                {splitTextByLength(comment?.name, 100)?.map(
                                  (line, idx) => (
                                    <div key={idx}>{line}</div>
                                  )
                                )}
                              </td>
                              <td
                                id="table-td-data"
                                style={{ textAlign: "center" }}
                              >
                                {splitTextByLength(
                                  comment?.description,
                                  200
                                )?.map((line, idx) => (
                                  <div key={idx}>{line}</div>
                                ))}
                              </td>
                              <td
                                id="table-td-data"
                                style={{ textAlign: "center" }}
                              >
                                {comment.filename && comment.download_url ? (
                                  <a
                                    href={comment.download_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {comment.filename}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      ) : (
                        <tr>
                          <td colSpan={6}>No Comments Added</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {ticketDetails.status.split("_")[1] !== "Closed" &&
                  ticketDetails.status.split("_")[1] !== "Resolved" && (
                    <div>
                      <div className="form-group mb-3">
                        <label htmlFor="ticketTitle">
                          <b>
                            Subject<span className="required">*</span>
                          </b>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          id="ticketTitle"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {formErrors.title && (
                          <span style={{ color: "#FF0000" }}>
                            {formErrors.title}
                          </span>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="ticketDescription">
                          <b>
                            Comments<span className="required">*</span>
                          </b>
                        </label>
                        <textarea
                          className="form-control"
                          id="ticketDescription"
                          rows="3"
                          placeholder="Description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        {formErrors.description && (
                          <span style={{ color: "#FF0000" }}>
                            {formErrors.description}
                          </span>
                        )}
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="fileUpload">
                          <b>Add File</b>
                        </label>
                        <input
                          type="file"
                          className="form-control-file"
                          id="fileUpload"
                          multiple
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="
File Formats supported: png, jpg, jpeg, gif, tiff, pdf, xls, xlsx, doc, docx, ppt, pptx, txt, html, json, csv"
                          style={{
                            border: "1px solid #80808069",
                            borderRadius: "3px",
                            padding: "3px",
                          }}
                        />
                        {formErrors.file && (
                          <div className="error" style={{ color: "#FF0000" }}>
                            {formErrors.file}
                          </div>
                        )}
                        {files.length > 0 && (
                          <>
                            {files.map((file, index) => (
                              <div key={index}>
                                {file.name}
                                <span
                                  onClick={() => handleFileDelete(file)}
                                  style={{
                                    marginLeft: "10px",
                                    color: "#ff0000",
                                    cursor: "pointer",
                                  }}
                                >
                                  <RiDeleteBin2Line title="Remove file" />
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                      <button
                        className="btn btn-success"
                        style={{ backgroundColor: "#61ce70" }}
                        onClick={sendComments}
                      >
                        Send
                      </button>
                    </div>
                  )}
              </>
            )}

            <div className="Button-grps" style={{ padding: "5px" }}>
              {ticketDetails.status.split("_")[1] === "Resolved" && (
                <>
                  <button
                    className="btn btn-light add-btn"
                    title="Reopen Ticket"
                    onClick={() => {
                      UpdateStatus("Open_Assigned");
                    }}
                  >
                    Reopen
                  </button>
                  <button
                    className="btn btn-light add-btn"
                    title="Close Ticket"
                    onClick={() => {
                      UpdateStatus("Closed_Closed");
                    }}
                  >
                    Close
                  </button>
                </>
              )}
              <button
                className="btn btn-light add-btn"
                onClick={handleComments}
                title={commentsTable ? "Hide Comments" : "Show Comments"}
              >
                {commentsTable ? (
                  <>
                    <IoIosArrowUp /> Comments
                  </>
                ) : (
                  <>
                    <IoIosArrowDown /> Comments
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
