import React, { useState,useEffect } from "react";
import "../Styles/Header.css";
import { useNavigate,useLocation } from "react-router-dom";
import FrothCrmLogo from "../Assets/CRM (1).png";
import { Navbar, Nav } from 'react-bootstrap';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activePage, setActivePage] = useState("/");

  useEffect(() => {
    // Update activePage based on the current pathname
    const path = location.pathname.toLowerCase();
    if (path === "/login" || path === "/self-service") {
      setActivePage("/SelfService");
    } else {
      setActivePage(location.pathname);
    }
  }, [location.pathname]);
  
  const handleNavigation = (path) => {
    setActivePage(path);
    navigate(path);
  };

  const handleExternalLink = (url) => {
    navigate("/");
    window.open(url, "_blank");
  };

  const handleContact = () => {
    localStorage.setItem("plan","")
    window.open('/ContactUs', '_blank');
    navigate("/");
  };

  const hanldePricing = () => {
    window.open('/Pricing', '_blank');
  };

  const hanldeSelfService = () => {
    window.open('/Login', '_blank');
  };

  return (
    <Navbar bg="white" expand="lg" fixed="top" className="shadow-sm" style={{ zIndex: 1000 }}>
      <div className="container">
      <div className="container-fluid" style={{fontWeight:'500',fontSize:'14px'}}>
        <Navbar.Brand onClick={() => handleNavigation("/")}>
          <img src={FrothCrmLogo} alt="FrothCRM Logo" style={{ height: '5rem' }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link 
              onClick={() => handleNavigation("/")}
              className={activePage === "/" ? "active" : ""}
            >
              Home
            </Nav.Link>
            <Nav.Link onClick={() => handleExternalLink("https://roboticodigital.com/")}>
              Robotico Digital
            </Nav.Link>
            <Nav.Link onClick={hanldeSelfService}
              className={activePage === "/SelfService" ? "active" : ""}>
              Self Service
            </Nav.Link>
            <Nav.Link onClick={hanldePricing} style={{marginRight:'1em'}}
            className={activePage === "/Pricing" ? "active" : ""}
            >
              Pricing
            </Nav.Link>
            <Nav.Link 
              id="contact-btn"
              className={`btn btn-danger ${activePage === "/ContactUs" ? "active" : ""}`}
              onClick={handleContact}
            >
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
      </div>
    </Navbar>
  );
};

export default Header;
