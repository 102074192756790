import React, { useState, useEffect } from "react";
import { apiUrl } from "../Pages/Config";
import "../Styles/Pricing.css"; // Assuming you put the CSS in this file

const PricingPage = () => {
  const [loading, setLoading] = useState(true);
  const [productDetails, setProductDetails] = useState(null);

  const accessToken = localStorage.getItem('accessToken');

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
          const productResponse = await fetch(
            `${apiUrl}fetchProductDetails/FrothCRM`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (productResponse.ok) {
            const productData = await productResponse.json();
            setProductDetails(productData);
          } else {
            alert("Failed to fetch product details. Please try again.");
          }
      } catch (error) {
        console.error("Error during login or fetching product details:", error);
        alert("Failed to login or fetch product details. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, []);

  const handleContact = () => {
    localStorage.setItem("plan","Enterprise Plan")
    window.open("/ContactUs", "_blank");
  };

  return (
    <div
      className="container"
      id="pricing-page"
      style={{ marginLeft: ".5rem", marginTop: "1rem" }}
    >
      <div className="text-center" id="header-part">
        <h1 className="plan-title">Pricing Plan</h1>
        <span class="hr-line"></span>
        <div
          className="form-check form-switch"
          style={{ paddingLeft: "0rem", display: "flex", gap: "5%" }}
        >
          {/* Toggle switch can be added here */}
        </div>
      </div>
      {!loading ? (
        <div
          className="row row-cols-1 row-cols-md-3 mb-3 text-center"
          style={{ display: "flex",gap:'5rem' }}
        >
          <div className="col-md-7 text-justify">
            <h5 style={{ lineHeight: "1.6" }}>
              <span className="tilte-content">Froth CRM</span> understands that
              every business has unique needs. That's why we offer special,
              customized pricing plans tailored to fit your specific
              requirements. Whether you're a startup or an established
              enterprise, our flexible options ensure you get the best value.
              Contact us today to find out how we can create a plan just for
              you!
            </h5>
            <h5 style={{ lineHeight: "1.6" }}>
              Explore the features and benefits of our advanced ticketing system
              designed to enhance your customer support experience. Discover how
              Froth CRM can streamline your processes and improve customer
              satisfaction.
            </h5>
            <ul class="custom-bullet-list">
                <li>Manage all your leads, contacts, and accounts with detailed profiles and activity tracking.</li>
                <li>Create comprehensive deals with custom fields, weighted probabilities, and automated task generation.</li>
                <li>Involves tracking and organizing tasks, calls, meetings, and emails, allowing users to effectively schedule, monitor, and follow up on essential interactions and actions to enhance collaboration, ensure timely follow-ups, and improve overall productivity.</li>
                <li>Visualize, customize, and forecast your sales pipeline with real-time deal tracking and drag-and-drop functionality.</li>
                <li>Generate professional proposals and invoices with customizable templates, product catalogs, and online payments.</li>
                <li>Managing product information, pricing, and other details.</li>
                <li>Enables businesses to efficiently handle customer support inquiries by creating, tracking, and resolving tickets, ensuring timely responses, and providing a comprehensive view of customer issues to enhance service quality and customer satisfaction.</li>
                <li>Automate repetitive tasks and trigger workflows based on specific criteria to boost efficiency.</li>
                <li>Design and send targeted email campaigns with A/B testing and detailed analytics to close more deals.</li>
                <li>Gain in-depth insights into your sales performance with customizable reports and key metric dashboards.</li>
                <li>Foster collaboration with internal notes, mentions, task assignments, and real-time data synchronization.</li>
                <li>Integrate FrothCRM with your favorite business applications for a unified data view and streamlined workflows.</li>
                <li>Ensure data security with role-based access controls, encryption, and comprehensive audit trails.</li>
                <li>FrothCRM scales seamlessly with your growing sales team and data volume.</li>
                <li>Get priority support from our team of CRM experts whenever you need assistance.</li>
                <li>Create and manage predefined templates for email communication.</li>
                <li>Design and manage templates for generating PDF documents, such as quotes, invoices, and contracts. also able to create custom templates for various documents.</li>
                <li>Create and export reports in various formats, including PDF and Excel.</li>
                <li>Display key document metrics and reports on customizable dashboards for quick insights.</li>
                <li>Allows users to easily upload, store, and organize files related to various records, providing seamless access and secure sharing, thereby enhancing collaboration and ensuring all critical information is readily available within the CRM.</li>
                <li>The Calendar in FrothCRM is a comprehensive scheduling tool that enables users to manage appointments, meetings, and tasks, providing a visual representation of daily, weekly, and monthly activities, facilitating efficient time management, and ensuring all team members stay aligned with upcoming events and deadlines.</li>
            </ul>
          </div>
          <div className="col">
            <div
              className="card mb-4 rounded-3 shadow-sm"
              style={{ margin: "10px", padding: "0" }}
            >
              <div
                className="card-header py-3"
                style={{ backgroundColor: "#54595F" }}
              >
                <h4
                  className="my-0 fw-normal text-white text-center"
                  id="enterprise-plan-title"
                >
                  ENTERPRISE PLAN
                </h4>
                <div className="corner-ribbon corner-ribbon-enterprise">
                  <span>PROFESSIONAL</span>
                </div>
              </div>
              <div className="card-body text-center">
                <ul
                  className="list-unstyled mt-3 mb-4"
                  id="enterprise-plan-description"
                >
                  {productDetails?.Enterprise_Plan?.description
                    .split("|")
                    .map((item, index) => (
                      <li key={index}>
                        {item}
                        {index <
                          productDetails?.Enterprise_Plan?.description.split(
                            "|"
                          ).length -
                            1 && <hr id="list-line" />}
                      </li>
                    ))}
                </ul>
                <button
                  type="button"
                  id="buy-now"
                  className="btn"
                  onClick={handleContact}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div class="text-center">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
      )}
    </div>
  );
};

export default PricingPage;
