import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideAddTicketPopup, setTickets } from "../../Pages/Store";
import { apiUrl } from "../../Pages/Config";
import { toast } from "react-toastify";
import { RiDeleteBin2Line } from "react-icons/ri";
import { BsInfoCircle } from "react-icons/bs";


const AddTicket = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [severity, setSeverity] = useState("choose");
  const [files, setFiles] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const topMargin = Object.keys(formErrors).length > 0 ? "22%" : "22%";

  const dispatch = useDispatch();
  const fileInputRef = useRef(null); // Reference to the file input

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
  
    const maxSizeMB = 5; // Max size per file
    const maxTotalSizeMB = 50; // Max total size
    const maxFiles = 10; // Max number of files
  
    let totalSize = 0;
    let validFiles = [...files];
    let errors = {};
  
    // Allowed file extensions
    const allowedExtensions = new Set([
     'png', 'jpg', 'jpeg', 'gif', 'tiff', 'pdf',
    'xls', 'xlsx', 'doc', 'docx', 'ppt', 'pptx',
    'txt', 'html', 'json', 'csv'])
  
    // Convert MB to bytes
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    const maxTotalSizeBytes = maxTotalSizeMB * 1024 * 1024;
  
    if (validFiles.length + selectedFiles.length > maxFiles) {
      errors.file = `Maximum files to upload: ${maxFiles}`;
    }
  
    // Validate each file
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (!allowedExtensions.has(fileExtension)) {
        errors.file = `Unsupported file format`
        break; // Stop further validation if unsupported file type found
      }
  
      if (file.size > maxSizeBytes) {
        errors.file = `Maximum upload file size: ${maxSizeMB} MB`;
        break; // Stop further validation if file size exceeds the limit
      } else {
        totalSize += file.size;
        validFiles.push(file);
      }
    }
  
    if (totalSize > maxTotalSizeBytes) {
      errors.file = `Total upload file size exceeds ${maxTotalSizeMB} MB`;
      validFiles = []; // Reset valid files
    }
  
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      fileInputRef.current.value = null; // Clear input field
    } else {
      setFiles(validFiles);
      setFormErrors({});
      fileInputRef.current.value = null; // Clear input field
    }
  };

  const handleFileDelete = (fileToDelete) => {
    setFiles(files.filter((file) => file !== fileToDelete));
  };

  const validateForm = () => {
    const errors = {};
    const trimmedTitle = title?.trim();
    if (!trimmedTitle) {
      errors.title = "Title required";
    } else if (trimmedTitle.length > 250) {
      errors.title = "Title cannot exceed 250 characters";
    }
    if (!description?.trim()) errors.description = "Description required";
    if (severity === "choose") errors.severity = "Select valid severity";
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const submitNewTicket = async () => {
    if (!validateForm()) return;

    try {
      const formData = new FormData();
      formData.append("account_id", localStorage.getItem("Id"));
      formData.append("name", title);
      formData.append("description", description);
      formData.append("severity", severity);
      formData.append("product", "FrothCRM");
      if(files){
      files.forEach((file) => {
        formData.append("attachments[]", file);
      });
    }

      const response = await fetch(`${apiUrl}createTicket`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
        body: formData,
      });

      if (response.status === 200) {
        toast.success("Ticket created successfully", {
          position: "top-right",
          autoClose: 3000,
        });

        const ticketResponse = await fetch(
          `${apiUrl}fetchUserTickets/${localStorage.getItem("Id")}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          }
        );
        const fetchResponse = await ticketResponse.json();
        const data =fetchResponse?.length>0 ? fetchResponse?.filter(
          (project) =>
            project.product_name &&
            project.product_name.toLowerCase() === "frothcrm"
        ) : [];
        dispatch(setTickets(data));

        dispatch(hideAddTicketPopup());
      } else if(!response?.success){
        const fetchResponse = await response.json();
        const errorMessage = fetchResponse.message
        toast.warn(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        });
        dispatch(hideAddTicketPopup());
      }
      else {
        toast.error("Failed to add ticket", {
          position: "top-right",
          autoClose: 3000,
        });
        dispatch(hideAddTicketPopup());
      }      
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  const handleCancel = () => {
    dispatch(hideAddTicketPopup());
  };

  return (
    <div
      id="addTicketPopup"
      style={{
        display: "block",
        position: "fixed",
        zIndex: 1050, // Make sure this is higher than the header
      }}
    >
      <div
        className="card popup"
        style={{ margin: "10px", padding: "1rem", top: topMargin }}
      >
        <div className="card-body text-left">
          <form
            style={{
              padding: "0.8rem",
              border: "1px solid #ccc",
              borderRadius: "5px",
              maxHeight:'27rem',
              overflow:'auto'
            }}
          >
            <div className="form-group mb-3">
              <label htmlFor="ticketTitle">
                <b>
                  Title<span className="required">*</span>
                </b>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Title"
                id="ticketTitle"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              {formErrors.title && (
                <span style={{ color: "#FF0000" }}>{formErrors.title}</span>
              )}
            </div>
            <div className="form-group mb-3">
              <label htmlFor="ticketDescription">
                <b>
                  Description<span className="required">*</span>
                </b>
              </label>
              <textarea
                className="form-control"
                id="ticketDescription"
                rows="3"
                placeholder="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
              {formErrors.description && (
                <span style={{ color: "#FF0000" }}>
                  {formErrors.description}
                </span>
              )}
            </div>
            <div className="form-group severity mb-3">
              <label htmlFor="severity">
                <b>
                  Severity<span className="required">*</span>
                </b>
              </label>
              <select
                className="form-select"
                id="severity"
                value={severity}
                onChange={(e) => setSeverity(e.target.value)}
              >
                <option value="choose">Choose...</option>
                <option value="S1">Severity 1</option>
                <option value="S2">Severity 2</option>
                <option value="S3">Severity 3</option>
                <option value="S4">Severity 4</option>
              </select>
              <span data-toggle="tooltip" data-placement="top"
                title="
                Severity 1 : System is down
Severity 2 : Product is working,functionality or workflow is broken
Severity 3 : Product is functional,user is facing minor or intermittent product issue 
Severity 4 : Product is functional with no apparent issues" ><BsInfoCircle /></span>
            </div>
            {formErrors.severity && (
              <span style={{ color: "#FF0000" }}>{formErrors.severity}</span>
            )}

            <div className="form-group mb-3">
              <label htmlFor="fileUpload">
                <b>Add File</b>
              </label>
              <input
                type="file"
                className="form-control-file"
                id="fileUpload"
                multiple
                ref={fileInputRef} // Attach the ref to the input
                onChange={handleFileChange}
                data-toggle="tooltip" data-placement="top"
                  title="
File Formats supported: png, jpg, jpeg, gif, tiff, pdf, xls, xlsx, doc, docx, ppt, pptx, txt, html, json, csv"
                style={{
                  border: "1px solid #80808069",
                  borderRadius: "3px",
                  padding: "3px",
                }}
              />
              {formErrors.file && (
                <div className="error" style={{ color: "#FF0000" }}>
                  {formErrors.file}
                </div>
              )}
              <div>
                {files.length > 0  && files.map((file, index) => (
                  <div key={index} className="file-item">
                    {file.name}
                    <span
                      onClick={() => handleFileDelete(file)}
                      style={{
                        marginLeft: "10px",
                        color: "#ff0000",
                        cursor: "pointer",
                      }}
                    >
                      <RiDeleteBin2Line title="Remove file" />
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="Button-grps">
              <button
                type="button"
                className="btn btn-light add-btn"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-light add-btn"
                onClick={submitNewTicket}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddTicket;
